<template>
  <div>Clear LocalStorage</div>
</template>
<script>
export default {
  mounted () {
    localStorage.clear()
  }
}
</script>
